<template>
  <div>
    <div v-if="showPanels">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="trainingClasses"
        :page.sync="page"
        :items-per-page="trainingClassesPerPage"
        :server-items-length="totalTrainingClasses"
        hide-default-footer
        @page-count="pageCount = $event"
        sort-by="name"
        class="elevation-1 ml-4"
      >
        <template v-slot:top>
          <v-card flat color="white">
            <v-row>
          <v-col
            cols="12"
            sm="3"
          >
          <v-select
            :items="intakes"
            item-text="name"
            item-value="id"
            v-model="filterIntake"
            dense
            outlined
            color="grey"
            hint="Filter by Intake"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
          <v-select
            :items="trainings"
            item-text="name"
            item-value="id"
            v-model="filterTraining"
            dense
            outlined
            color="grey"
            hint="Filter by Trade"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-col
            cols="12"
            sm="1"
          >
          <v-btn
            class="mt-8"
            elevation="1"
            fab
            small
            @click="loadTrainingClasses"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn rounded disabled class="mt-10">{{currentCenter.name}}</v-btn>
          </v-row>
          <v-row>
            <v-col
            cols="12"
            sm="10"
          >
          </v-col>
          <v-dialog v-model="dialog" max-width="1200px">
            <template v-slot:activator="{ on }">
              <v-spacer></v-spacer>
            <v-btn color="green" dark class="mb-2" v-on="on" small right v-if="showCreate">Add Class</v-btn>
          </template>
          <v-card>
            <validation-observer ref="form">
            <form @submit.prevent="onSubmit">
            <v-card-title class="mb-12">
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                    <v-col
                    cols="12"
                    sm="3"
                  >
                  <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="Class Name"
                    >
                    <v-text-field
                      dense
                      label="Class Name"
                      outlined
                      name="Class Name"
                      v-model="editedTrainingClass.name"
                      :error-messages="errors"
                      class="mt-n6"
                    />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                  <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="Training"
                    >
                    <v-select
                      :items="trainings"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      label="Trade"
                      outlined
                      name="Training"
                      v-model="editedTrainingClass.training"
                      :error-messages="errors"
                      class="mt-n6"
                      @change="loadTrainingLevels"
                    />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="Start Date"
                    >
                      <v-text-field
                        v-model="formattedStartDate"
                        label="Start Date"
                        outlined
                        name="Start Date"
                        :error-messages="errors"
                        class="mt-n6"
                        readonly
                        dense
                        v-on="on"
                      ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="startDate"
                      min="1900-01-01"
                      @change="saveStartDate"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedEndDate"
                        label="End Date"
                        outlined
                        class="mt-n6"
                        readonly
                        append-icon="mdi-delete"
                        @click:append="clearEndDate"
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="endDate"
                      :min="tomorrow"
                      @change="saveEndDate"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      :items="intakes"
                      item-text="name"
                      item-value="id"
                      dense
                      return-object
                      label="Intake"
                      v-model="editedTrainingClass.intake"
                      class="mt-n6"
                      outlined
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      :items="trainingLevels"
                      item-text="level"
                      item-value="id"
                      dense
                      return-object
                      label="Trade Level"
                      v-model="editedTrainingClass.trainingLevel"
                      class="mt-n6"
                      outlined
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      dense
                      label="Comment"
                      outlined
                      v-model="editedTrainingClass.comment"
                      class="mt-n6"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-select
                      :items="states"
                      dense
                      label="Status"
                      outlined
                      v-model="editedTrainingClass.state"
                      class="mt-n6"
                    />
                  </v-col>
                  </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
            <v-btn color="green" dark class="ml-12 mt-n10 mb-4" type="submit">Save</v-btn>
            <v-btn class="mt-n10 mb-4" @click="close">Cancel</v-btn>
            </v-card-actions>
            </form>
            </validation-observer>
          </v-card>
        </v-dialog>
          </v-row>
          </v-card>
        </template>
        <template v-slot:item.name="{ item }">
          <p @click="switchToClass(item)" class=" btn blue--text">{{ item.name }}</p>
        </template>
        <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editTrainingClass(item)"
        v-if="showEdit"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="trainingClassDialog(item)"
        v-if="showDelete"
      >
        mdi-delete
      </v-icon>
      <v-icon
        small
        @click="completeTrainingClassDialog(item)"
        v-if="item.state !== 'COMPLETED'"
      >
        mdi-check
      </v-icon>
    </template>
      </v-data-table>
        <div class="pt-2 px-12">
            <v-row>
            <v-col cols="12" sm="2">
            <v-select
              :items="selectTrainingClassesPerPage"
              v-model="trainingClassesPerPage"
              label="Trade Classes per page"
              dense
            ></v-select>
            </v-col>
            <v-col cols="12" sm="8">
            <v-pagination
              v-model="page"
              :length="pageCount"
              circle
              total-visible="7"
              color="blue"
            ></v-pagination>
            </v-col>
            <v-col cols="12" sm="2">
              {{totalTrainingClasses}} Records
            </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="!showPanels">
      </div>
  <v-btn @click="showPanels = true" class="ml-4 mt-4" icon v-if="!showPanels"><v-icon>mdi-arrow-left</v-icon>Back</v-btn>
  <v-snackbar
      v-model="snackbar"
      top
      :color="color"
      :timeout="timeout"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="setSnackbar(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="completeDialog" hide-overlay max-width="430px" persistent>
    <v-card tile outlined>
        <v-card-text>
          <p>Complete Trade Class. Note that completed class can neither be edited nor deleted</p>
          <v-menu
            ref="completeDateMenu"
            v-model="completeDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedCompleteDate"
                outlined
                filled
                color="grey"
                dense
                hint="End Date"
                persistent-hint
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="completeDate"
              min="1900-01-01"
              :max="new Date().toISOString().substr(0, 10)"
              no-title
              @change="saveCompleteDate"
            ></v-date-picker>
          </v-menu>
          <v-btn link small color="blue" @click="completeTrainingClass">Save</v-btn>
          <v-btn link small color="blue" @click="completeDialog = false">Cancel</v-btn>
        </v-card-text>
      </v-card>
  </v-dialog>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this trade class ?</p>
          <v-btn link small color="blue" @click="deleteTrainingClass()">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false">No</v-btn>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>
<script>
// import { WebCam } from 'vue-web-cam'
import { mapState, mapMutations, mapActions } from 'vuex'
import TrainingClass from '@/models/TrainingClass'
export default {
  data () {
    return {
      dialog: false,
      privileges: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      deleteDialog: false,
      completeDialog: false,
      showPanels: false,
      names: null,
      headers: [
        {
          text: 'Class Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Intake', value: 'intake.name' },
        { text: 'Trade', value: 'training.name' },
        { text: 'Trade Level', value: 'trainingLevel.level' },
        { text: 'Start Date', value: 'startDate' },
        { text: 'End Date', value: 'endDate' },
        { text: 'Status', value: 'state' },
        { text: 'Comment', value: 'comment' },
        { text: 'Trainees', value: 'numberOfTrainees' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      trainingClasses: [],
      trainings: [],
      trainingLevels: [],
      states: ['ONGOING', 'COMPLETED', 'NOT_STARTED', 'ABORTED'],
      selectTrainingClassesPerPage: [2, 5, 10, 15, 20, 25, 30],
      page: 1,
      pageCount: 0,
      trainingClassesPerPage: 20,
      totalTrainingClasses: 0,
      singleSelect: false,
      filterIntake: null,
      filterTraining: null,
      selected: [],
      intakes: [],
      editedTrainingClassIndex: -1,
      editedTrainingClass: new TrainingClass(),
      trainingClassItem: null,
      transferId: null,
      startDate: null,
      retrievedStartDate: null,
      startDateMenu: false,
      endDate: null,
      retrievedEndDate: null,
      endDateMenu: false,
      completeDate: new Date().toISOString().substr(0, 10),
      retrievedCompleteDate: null,
      completeDateMenu: false,
      errors: null,
      tomorrow1: null,
      panel: 3
    }
  },

  computed: {
    formTitle () {
      return this.editedTrainingClassIndex === -1 ? 'Add Trade Class' : 'Edit Trade Class'
    },
    formattedStartDate () {
      return this.startDate ? this.$moment(this.startDate).format('DD-MMM-YYYY')
        : this.retrievedStartDate ? this.$moment(this.retrievedStartDate).format('DD-MMM-YYYY')
          : ''
    },
    formattedEndDate () {
      return this.endDate ? this.$moment(this.endDate).format('DD-MMM-YYYY')
        : this.retrievedEndDate ? this.$moment(this.retrievedEndDate).format('DD-MMM-YYYY')
          : ''
    },
    formattedCompleteDate () {
      return this.completeDate ? this.$moment(this.completeDate).format('DD-MMM-YYYY')
        : this.retrievedCompleteDate ? this.$moment(this.retrievedCompleteDate).format('DD-MMM-YYYY')
          : ''
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser', 'currentCenter', 'search', 'storeProvinces', 'storeProvincesError', 'deviantActs', 'identificationDocuments', 'apprehensionItems'])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadTrainingClasses()
      }
    },
    trainingClassesPerPage: {
      handler () {
        this.page = 1
        this.loadTrainingClasses()
      }
    }
  },
  mounted () {
    this.loadTrainingClasses()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.loadTrainings()
    this.loadIntakes()
    this.showPanels = true
    this.setTomorrow()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['switchToClass']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setTrainingClass: 'SET_TRAINING_CLASS'
    }),
    loadTrainingClasses () {
      const pageNumber = this.page - 1
      this.$delinquentService.fetchTrainingClasses(this.filterIntake, 'numberOfTrainees', this.filterTraining, pageNumber, this.trainingClassesPerPage)
        .then(response => {
          if (!response.status) {
            this.trainingClasses = response.content
            this.totalTrainingClasses = response.totalElements
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Trade Classes: ' + response.message)
          }
        })
    },
    loadTrainings () {
      this.$delinquentService.fetchTrainingNames()
        .then(response => {
          if (!response.status) {
            this.trainings = response
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Trades: ' + response.message)
          }
        })
    },
    loadTrainingLevels () {
      this.$delinquentService.fetchTrainingLevelsById(this.editedTrainingClass.training.id)
        .then(response => {
          if (!response.status) {
            this.trainingLevels = response
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Trade Levels: ' + response.message)
          }
        })
    },
    loadIntakes () {
      this.$delinquentService.fetchIntakes('OPEN')
        .then(response => {
          if (!response.status) {
            this.intakes = response
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedTrainingClass = Object.assign({}, this.defaultTrainingClassItem)
        this.editedTrainingClassIndex = -1
      }, 300)
    },
    editTrainingClass (item) {
      if (item.state !== 'COMPLETED') {
        this.editedTrainingClassIndex = this.trainingClasses.indexOf(item)
        this.$delinquentService.fetchTrainingClassById(item.id, null)
          .then(response => {
            if (response.id) {
              this.editedTrainingClass = response
              this.trainingLevels = [response.trainingLevel]
              this.retrievedStartDate = response.startDate
              this.retrievedEndDate = response.endDate
            } else {
              // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
        this.dialog = true
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('This class is completed')
      }
    },
    trainingClassDialog (item) {
      if (item.state !== 'COMPLETED') {
        this.trainingClassIndex = this.trainingClasses.indexOf(item)
        this.trainingClassItem = item
        this.deleteDialog = true
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('This class is completed')
      }
    },
    deleteTrainingClass () {
      this.deleteDialog = false
      this.$delinquentService.deleteTrainingClass(this.trainingClassItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.trainingClasses.splice(this.trainingClassIndex, 1)
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    completeTrainingClassDialog (item) {
      this.trainingClassIndex = this.trainingClasses.indexOf(item)
      this.trainingClassItem = item
      this.completeDialog = true
    },
    completeTrainingClass () {
      if (this.trainingClassItem.state !== 'NOT_STARTED') {
        this.$delinquentService.completeTrainingClass(this.trainingClassItem.id, this.formattedCompleteDate)
          .then(response => {
            if (response.status === 'success') {
              this.trainingClasses[this.trainingClassIndex].endDate = this.formattedCompleteDate
              this.trainingClasses[this.trainingClassIndex].state = 'COMPLETED'
              this.close()
            } else {
              // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('This class have never started')
      }
    },
    clearEndDate () {
      if (this.editedTrainingClass.id) {
        this.trainingClasses[this.editedTrainingClassIndex].endDate = null
        this.retrievedEndDate = null
        this.editedTrainingClass.endDate = null
      } else {
        this.endDate = null
      }
    },
    updateTrainingClass () {
      this.editedTrainingClass.startDate = this.formattedStartDate
      this.editedTrainingClass.endDate = this.formattedEndDate
      const deletedTrainingClassArray = this.trainingClasses.splice(this.editedTrainingClassIndex, 1)
      this.$delinquentService.saveTrainingClasses(this.editedTrainingClass)
        .then(response => {
          if (response.id) {
            this.trainingClasses.splice(this.editedTrainingClassIndex, 0, response)
            this.close()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.trainingClasses.splice(this.editedTrainingClassIndex, 0, deletedTrainingClassArray[0])
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createTrainingClass () {
      this.editedTrainingClass.startDate = this.formattedStartDate
      this.editedTrainingClass.endDate = this.formattedEndDate
      this.$delinquentService.saveTrainingClasses(this.editedTrainingClass)
        .then(response => {
          if (response.id) {
            this.trainingClasses.push(response)
            this.close()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedTrainingClassIndex > -1) {
          this.updateTrainingClass()
        } else this.createTrainingClass()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    saveStartDate (startDate) {
      this.$refs.startDateMenu.save(startDate)
    },
    saveEndDate (endDate) {
      this.$refs.endDateMenu.save(endDate)
    },
    saveCompleteDate (completeDate) {
      this.$refs.completeDateMenu.save(completeDate)
    },
    setTomorrow () {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      this.tomorrow = tomorrow.toISOString().substr(0, 10)
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_TRAININGCLASSES') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showCreate = true : this.showCreate = false
      this.privileges.indexOf('UPDATE_TRAININGCLASSES') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('DELETE_TRAININGCLASSES') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
